import React, { useState } from "react";
import { validateFile } from "../utils/fileValidation";
import PageTransition from "../components/PageTransition";

const API_ENDPOINT = `${process.env.REACT_APP_API_BASE_URL}/job-application`;

const Join = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    coverLetter: "",
    resume: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [submitStatus, setSubmitStatus] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError(""); // Clear any previous errors
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    try {
      if (file) {
        validateFile(file);
        setFormData((prev) => ({
          ...prev,
          resume: file,
        }));
        setError("");
      }
    } catch (err) {
      setError(err.message);
      e.target.value = ""; // Reset file input
      setFormData((prev) => ({
        ...prev,
        resume: null,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSubmitStatus("");
    setIsSubmitting(true);

    try {
      // Convert file to base64 if it exists
      let fileContent = "";
      let fileName = "";
      let fileType = "";

      if (formData.resume) {
        fileName = formData.resume.name;
        fileType = formData.resume.type;

        // Read file as base64
        try {
          fileContent = await new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function () {
              try {
                // The result should be a data URL
                if (
                  typeof reader.result === "string" &&
                  reader.result.includes("base64,")
                ) {
                  // Extract just the base64 part after the comma
                  const base64Data = reader.result.split("base64,")[1];
                  resolve(base64Data);
                } else {
                  console.error(
                    "Unexpected FileReader result format:",
                    reader.result.substring(0, 50) + "..."
                  );
                  reject(
                    new Error(
                      "File couldn't be processed in the expected format."
                    )
                  );
                }
              } catch (err) {
                console.error("Error processing FileReader result:", err);
                reject(err);
              }
            };

            reader.onerror = function () {
              console.error("FileReader error:", reader.error);
              reject(new Error("Error reading the file."));
            };

            // Use readAsDataURL to get a base64 data URL
            reader.readAsDataURL(formData.resume);
          });

          if (!fileContent || fileContent.length === 0) {
            throw new Error("The file was read but no content was extracted.");
          }
        } catch (fileError) {
          console.error("Error preparing file:", fileError);
          throw new Error(
            "Failed to process the file. Please try a different file or format."
          );
        }
      }

      // Create JSON payload
      const payload = {
        requestType: "submitApplication",
        name: formData.name,
        email: formData.email,
        phone: formData.phone || "",
        coverLetter: formData.coverLetter,
        fileName: fileName,
        fileContent: fileContent,
        fileType: fileType,
      };

      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          data.message || data.error || "Failed to submit application"
        );
      }

      setSubmitStatus("success");
      e.target.reset();
      setFormData({
        name: "",
        email: "",
        phone: "",
        coverLetter: "",
        resume: null,
      });
    } catch (error) {
      console.error("Error:", error);
      setError(
        error.message ||
          "We're unable to process your application at this time. Please try again later or contact us directly."
      );
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <PageTransition>
      <div className="container mx-auto px-6 md:px-4 py-8 md:py-16 max-w-3xl">
        <h1 className="text-[32px] md:text-[43.2px] text-[#6B492A] font-[700] tracking-[2px] md:tracking-[6px] font-['Domine'] leading-[1.1] mb-8 text-center">
          JOBS
        </h1>

        <p className="text-gray-700 mb-8 md:mb-12 text-center text-base md:text-lg">
          We'd love to have you join our team! Please use the form below to
          apply. Feel free to send in your resume even if you don't see the
          position you're looking for listed.
        </p>

        {error && (
          <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
            {error}
          </div>
        )}
        {submitStatus === "success" && (
          <div className="bg-green-50 text-green-600 p-4 rounded-lg mb-6">
            Application submitted successfully! We'll be in touch soon.
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-[#6B492A] mb-2">Name *</label>
            <input
              type="text"
              name="name"
              required
              value={formData.name}
              onChange={handleInputChange}
              disabled={isSubmitting}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none
                       focus:border-[#6B492A] disabled:bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-[#6B492A] mb-2">Email *</label>
            <input
              type="email"
              name="email"
              required
              value={formData.email}
              onChange={handleInputChange}
              disabled={isSubmitting}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none
                       focus:border-[#6B492A] disabled:bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-[#6B492A] mb-2">Phone Number *</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              disabled={isSubmitting}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none
                       focus:border-[#6B492A] disabled:bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-[#6B492A] mb-2">Cover Letter *</label>
            <textarea
              name="coverLetter"
              required
              value={formData.coverLetter}
              onChange={handleInputChange}
              disabled={isSubmitting}
              rows="6"
              className="w-full p-3 border border-gray-300 rounded focus:outline-none
                       focus:border-[#6B492A] disabled:bg-gray-100"
            ></textarea>
          </div>
          <div>
            <label className="block text-[#6B492A] mb-2">
              Resume (PDF or Word, max 5MB)
            </label>
            <input
              type="file"
              name="resume"
              onChange={handleFileChange}
              disabled={isSubmitting || submitStatus === "success"}
              accept=".pdf,.doc,.docx"
              className="w-full p-3 border border-gray-300 rounded focus:outline-none
                       focus:border-[#6B492A] disabled:bg-gray-100"
            />
            {formData.resume && !error && (
              <p className="text-green-600 text-sm mt-1">
                File selected: {formData.resume.name}
              </p>
            )}
          </div>
          <div className="flex justify-center w-full mt-6">
            <button
              type="submit"
              disabled={isSubmitting || error}
              className="bg-[#64785D] text-white px-8 py-2 rounded hover:bg-[#6B492A] disabled:bg-gray-400"
            >
              {isSubmitting ? "Submitting..." : "Apply"}
            </button>
          </div>
        </form>
      </div>
    </PageTransition>
  );
};

export default Join;
