import React from "react";
import PageTransition from "../components/PageTransition";
const Hours = () => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const address = {
    street: "1225 Oak Cir Drive",
    city: "Arnold",
    state: "CA",
    zip: "95223",
  };
  const phone = "(209) 890-7849";

  const openGoogleMaps = () => {
    const encodedAddress = encodeURIComponent(
      `${address.street}, ${address.city}, ${address.state} ${address.zip}`
    );
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`,
      "_blank"
    );
  };

  return (
    <PageTransition>
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-[43.2px] text-[#6B492A] font-['Domine'] mb-6 uppercase tracking-[6px] leading-[1.1] text-center font-[900]">
          Hours & Location
        </h1>
        {/* Address and Contact Section */}
        <div className="text-center mb-12">
          <div
            onClick={openGoogleMaps}
            className="cursor-pointer hover:text-[#6B492A] mb-2"
          >
            <p className="text-xl">{address.street},</p>
            <p className="text-xl">
              {address.city}, {address.state} {address.zip}
            </p>
          </div>
          <a
            href={`tel:${phone.replace(/[^0-9]/g, "")}`}
            className="text-xl text-blue-600 hover:text-[#6B492A]"
          >
            {phone}
          </a>
        </div>
        {/* Hours Section */}
        <div className="text-center mb-16">
          <p className="text-lg mb-2">
            <span className="font-bold">Monday - Thursday:</span> 8am to 5pm
          </p>
          <p className="text-lg">
            <span className="font-bold">Friday - Sunday:</span> 8am to 7pm
          </p>
        </div>
        {/* Map Section with Overlaid Button */}
        <div className="relative w-full h-[400px]">
          <iframe
            title="Bistro Location"
            src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=${encodeURIComponent(
              `${address.street}, ${address.city}, ${address.state} ${address.zip}`
            )}`}
            className="w-full h-full border-0"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          {/* Overlaid Get Directions Button */}
          <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2">
            <button
              onClick={openGoogleMaps}
              className="bg-[#64785D] text-[14px] font-['Lato'] text-white px-8 py-3 pb-[13px]
                       hover:bg-[#6B492A] transition-colors tracking-wider"
            >
              GET DIRECTIONS
            </button>
          </div>
        </div>
      </div>
    </PageTransition>
  );
};

export default Hours;
