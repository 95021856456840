import about3 from "../assets/images/about/bistro_banner.avif";
import about2 from "../assets/images/about/burger.avif";
import about1 from "../assets/images/about/inside_bistro.avif";

export const slideshowImages = [
  {
    url: about1,
    // alt: "Bistro Interior View",
  },
  {
    url: about2,
    // alt: "Bistro Food Preparation",
  },
  {
    url: about3,
    // alt: "Bistro Dining Area",
  },
];
