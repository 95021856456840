import React from "react";
import creameryBanner from "../assets/images/banner/bistro_creamery.avif";
import { menuItemsCreamery, flavorsList } from "../data/creameryData";
import PageTransition from "../components/PageTransition";

const Creamery = () => {
  const scrollToContent = () => {
    document
      .getElementById("creamery-content")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <PageTransition>
      <div className="flex flex-col">
        {/* Banner Image */}
        <div className="relative h-[400px] md:h-[600px] w-full overflow-hidden">
          <img
            src={creameryBanner}
            alt="Bistro Creamery"
            className="absolute inset-0 w-full h-full object-contain md:object-cover object-center"
          />
          <div className="absolute inset-0 flex flex-col items-center">
            <div className="flex-1 flex items-center justify-center">
              {/* <h1 className="text-5xl font-bold text-white">Bistro Creamery</h1> */}
            </div>
            <div className="pb-8 md:pb-12">
              <button
                onClick={scrollToContent}
                className="animate-bounce"
                aria-label="Scroll to content"
              >
                <svg
                  className="w-12 h-12 md:w-16 md:h-16 text-white drop-shadow-lg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Content Section */}
        <div className="container mx-auto px-6 md:px-[4.5rem] py-12 md:py-20 text-center">
          <p className="font-['Lato'] text-[18px] md:text-[22px] leading-[1.6] max-w-[800px] mx-auto text-center">
            The Bistro Espresso, Inc. is pleased to announce the opening of its
            sister company, The Bistro Creamery June, 2021. This little shop is
            located in Cedar Center in the center of town just across from the
            Bistro. Come taste the sweetness and enjoy ice cream by the scoop,
            milkshakes, malts, sundaes, and splits. Our friendly staff is so
            excited to serve the families and visitors of the Arnold area!
          </p>
        </div>
        {/* Menu Section */}
        <div
          id="creamery-content"
          className="container mx-auto px-6 md:px-[4.5rem] pb-12 md:pb-20"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-20 max-w-[1200px] mx-auto">
            {/* Menu Column */}
            <div className="text-center">
              <h2 className="font-['Domine'] text-[32px] md:text-[38px] text-[#6B492A] mb-8 md:mb-10 uppercase font-[900]">
                MENU
              </h2>
              <div className="space-y-6 md:space-y-8">
                {menuItemsCreamery.map((item, index) => (
                  <div key={index} className="flex flex-col">
                    <div className="flex justify-between text-[18px] md:text-[21px]">
                      <span className="font-bold uppercase">{item.name}</span>
                      <span className="font-semibold text-[#6B492A]">
                        {item.price}
                      </span>
                    </div>
                    {item.additionalOptions && (
                      <div className="mt-3 text-left pl-8">
                        {item.additionalOptions.map((option, optIndex) => (
                          <div
                            key={optIndex}
                            className="flex justify-between text-[16px] md:text-[19px]"
                          >
                            <span className="italic">{option.name}</span>
                            <span className="text-[#6B492A] italic">
                              {option.price}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {/* Flavors Column */}
            <div className="text-center md:pl-16">
              <h2 className="font-['Domine'] text-[32px] md:text-[38px] text-[#6B492A] mb-8 md:mb-10 uppercase font-[900]">
                FLAVORS
              </h2>
              <div className="space-y-2 md:space-y-2">
                {flavorsList.map((flavor, index) => (
                  <p
                    key={index}
                    className="font-['Lato'] text-[17px] md:text-[19px] leading-[1.4]"
                  >
                    {flavor}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTransition>
  );
};

export default Creamery;
