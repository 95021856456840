import React from "react";
import { Link } from "react-router-dom";
import { eventsData } from "../data/eventsData";
import PageTransition from "../components/PageTransition";

const Events = () => {
  const eventsArray = Object.values(eventsData);

  return (
    <PageTransition>
      <div className="container mx-auto px-4 md:px-[5rem] py-8 md:py-16">
        <h1 className="text-[32px] md:text-[43.2px] text-[#6B492A] font-[700] tracking-[2px] md:tracking-[6px] font-['Domine'] uppercase text-center mb-8 md:mb-16">
          Events
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 md:gap-8 lg:gap-12 max-w-[1400px] mx-auto">
          {eventsArray.map((event) => (
            <Link
              key={event.id}
              to={`/event/${event.id}`}
              className="group block no-underline mx-auto w-full max-w-[400px] md:max-w-none"
            >
              <div className="overflow-hidden rounded-lg aspect-[4/3]">
                <img
                  src={event.image}
                  alt={event.title}
                  className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                />
              </div>
              <h2 className="text-[1.5rem] md:text-[1.75rem] lg:text-[2rem] tracking-[2px] md:tracking-[3px] font-[600] font-['Domine'] text-[#6B492A] mt-4 leading-tight text-center">
                {event.title}
              </h2>
            </Link>
          ))}
        </div>
      </div>
    </PageTransition>
  );
};

export default Events;
