// src/components/Footer.js
import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-white py-6 border-t">
      <div className="px-4 md:px-[1.875rem] flex justify-between items-center">
        {/* Social Media Icons */}
        <div className="flex gap-2.5">
          <a
            href="https://www.facebook.com/BistroEspresso/"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#6b492a] text-white p-1.5 rounded-full hover:opacity-90 transition-opacity"
          >
            <FaFacebookF size={17} />
          </a>
          <a
            href="https://www.instagram.com/bistro_espresso/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#6b492a] text-white p-1.5 rounded-full hover:opacity-90 transition-opacity"
          >
            <FaInstagram size={17} />
          </a>
        </div>

        {/* Navigation Links */}
        <div className="flex items-center gap-3.5">
          <Link
            to="/contact"
            className="text-gray-800 hover:text-[#6b492a] transition-colors font-semibold text-[13px]"
          >
            CONTACT
          </Link>
          <Link
            to="/jobs"
            className="bg-[#64785d] text-white px-3.5 py-1.5 rounded hover:opacity-90 transition-opacity text-[13px]"
          >
            JOIN US
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
