// src/pages/Menu.js
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { menuSections } from "../data/menuItems";
import { menuItemsCreamery, flavorsList } from "../data/creameryData";
import PageTransition from "../components/PageTransition";
// Import PDFs directly

import menuBanner from "../assets/images/banner/menu_burger.avif";
// Import placeholder menu image

import breakfastMenu from "../assets/menu_images/breakfast.JPG";
import sandwichesMenu from "../assets/menu_images/sandwiches.JPG";
import appetizerMenu from "../assets/menu_images/appetizer.JPG";
import beveragesMenu from "../assets/menu_images/beverages.JPG";

const Menu = () => {
  const [activeSection, setActiveSection] = useState("FULL MENU");
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [key, setKey] = useState(0);

  // Map of section names to imported images
  const menuImages = {
    BREAKFAST: breakfastMenu,
    "SANDWICHES & WRAPS": sandwichesMenu,
    "APPETIZER, SALAD, KIDS MENU": appetizerMenu,
    "COFFEE AND BEVERAGE": beveragesMenu,
  };

  const handleSectionChange = (section) => {
    setKey((prevKey) => prevKey + 1);
    setActiveSection(section);
  };

  const handleImageClick = (image, section) => {
    if (activeSection === "FULL MENU") {
      handleSectionChange(section);
    } else {
      setSelectedImage(image);
      setShowModal(true);
    }
  };

  const scrollToMenu = () => {
    document
      .getElementById("menu-sections")
      .scrollIntoView({ behavior: "smooth" });
  };

  // Modal Component
  const Modal = () => {
    return ReactDOM.createPortal(
      <div
        className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4"
        style={{
          position: "fixed",
          zIndex: 9999,
        }}
        onClick={() => setShowModal(false)}
      >
        <div
          className="relative max-w-[95vw] max-h-[95vh] overflow-auto bg-white rounded-lg"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Close button */}
          <button
            className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-md hover:bg-gray-100"
            style={{ zIndex: 10000 }}
            onClick={() => setShowModal(false)}
          >
            <svg
              className="w-6 h-6 text-gray-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          {/* Modal Image */}
          <img
            src={selectedImage}
            alt="Menu Detail"
            className="w-full h-auto object-contain"
            style={{ maxHeight: "85vh" }}
          />
        </div>
      </div>,
      document.getElementById("modal-root")
    );
  };

  return (
    <PageTransition>
      <div className="flex flex-col">
        {/* Banner Section */}
        <div className="relative h-[40vh] md:h-[60vh] w-full">
          <div className="absolute inset-0 bg-black/60">
            <img
              src={menuBanner}
              alt="Menu Banner"
              className="w-full h-full object-cover object-center mix-blend-overlay"
            />
          </div>
          <div className="absolute inset-0 flex flex-col items-center">
            {/* Title positioned at center */}
            <div className="flex-1 flex items-center justify-center mt-8">
              <h1 className="text-[2.5rem] md:text-[4.5rem] font-[700] tracking-[4px] md:tracking-[6px] mb-4 font-['Domine'] leading-[1.1] text-white uppercase">
                Menus
              </h1>
            </div>
            {/* Arrow positioned at bottom with padding */}
            <div className="pb-8 md:pb-12">
              <button
                onClick={scrollToMenu}
                className="animate-bounce"
                aria-label="Scroll to menu sections"
              >
                <svg
                  className="w-12 h-12 md:w-16 md:h-16 text-white drop-shadow-lg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Menu Section Buttons */}
        <div id="menu-sections" className="container mx-auto px-4 py-8">
          {/* Top Row Buttons */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2 md:gap-3 mb-2 md:mb-3 max-w-[1200px] mx-auto">
            {menuSections.topRow.map((section) => (
              <button
                key={section}
                onClick={() => handleSectionChange(section)}
                className={`py-2 md:py-3 px-3 md:px-4 text-[12px] md:text-[14px] font-['Lato'] font-semibold rounded-lg 
                  transition-all duration-300 ease-in-out transform hover:scale-105 
                  w-full h-[40px] md:h-[50px] tracking-[1px] md:tracking-[2px] flex items-center justify-center
                  ${
                    activeSection === section
                      ? "bg-[#6B492A] text-white"
                      : "bg-[#64785D] text-white hover:bg-[#6B492A]/90"
                  }`}
              >
                <span className="text-center px-1">{section}</span>
              </button>
            ))}
          </div>
          {/* Bottom Row Buttons */}
          <div className="flex flex-col sm:flex-row justify-center gap-2 md:gap-3">
            {menuSections.bottomRow.map((section) => (
              <button
                key={section}
                onClick={() => handleSectionChange(section)}
                className={`py-2 md:py-3 px-3 md:px-4 text-[12px] md:text-[14px] font-['Lato'] font-semibold rounded-lg 
                  transition-all duration-300 ease-in-out transform hover:scale-105 
                  h-[40px] md:h-[50px] tracking-[1px] md:tracking-[2px] flex items-center justify-center
                  min-w-[150px] md:min-w-[200px]
                  ${
                    activeSection === section
                      ? "bg-[#6B492A] text-white"
                      : "bg-[#64785D] text-white hover:bg-[#6B492A]/90"
                  }`}
              >
                <span className="text-center px-1">{section}</span>
              </button>
            ))}
          </div>
          {/* Menu Content with Transitions */}
          <div className="mt-8 md:mt-12 max-w-6xl mx-auto px-4">
            {/* Full Menu Display - Grid of all menus */}
            <div
              key={key}
              className="transition-opacity duration-300 ease-in-out"
              style={{
                opacity: 1,
                animation: "fadeIn 0.5s ease-in-out",
              }}
            >
              {activeSection === "FULL MENU" ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
                  {Object.entries(menuImages).map(([section, image]) => (
                    <div
                      key={section}
                      className="relative group cursor-pointer"
                      onClick={() => handleSectionChange(section)}
                    >
                      <div className="overflow-hidden rounded-lg shadow-lg">
                        <img
                          src={image}
                          alt={`${section} Menu`}
                          className="w-full h-auto object-cover transition-transform duration-300 group-hover:scale-105"
                        />
                        <div className="absolute inset-0 bg-black/40 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <h3 className="text-white text-xl md:text-2xl font-semibold text-center px-4 font-['Domine']">
                            {section}
                          </h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                /* Single Menu Display */
                <div className="w-full flex justify-center">
                  {activeSection !== "BISTRO CREAMERY" && (
                    <div
                      className="overflow-hidden rounded-lg shadow-lg max-w-3xl w-full cursor-pointer"
                      onClick={() =>
                        handleImageClick(menuImages[activeSection])
                      }
                    >
                      <img
                        src={menuImages[activeSection]}
                        alt={`${activeSection} Menu`}
                        className="w-full h-auto object-cover"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Replace the existing modal code with this */}
            {showModal && <Modal />}

            {/* Bistro Creamery section remains unchanged */}
            {activeSection === "BISTRO CREAMERY" && (
              <div
                className="transition-opacity duration-300 ease-in-out"
                style={{
                  opacity: 1,
                  animation: "fadeIn 0.3s ease-in-out",
                }}
              >
                {/* Menu Items */}
                <div className="mb-12">
                  <h2 className="text-[32px] font-['Domine'] text-2xl font-semibold text-center mb-8 text-[#6B492A] uppercase">
                    Menu
                  </h2>
                  <div className="grid md:grid-cols-2 gap-x-16 gap-y-6">
                    {menuItemsCreamery.map((item, index) => (
                      <div
                        key={index}
                        className="flex justify-between gap-4 border-b border-gray-200 pb-4"
                      >
                        <div className="flex-grow">
                          <h3 className="font-semibold text-gray-800">
                            {item.name}
                          </h3>
                          {item.additionalOptions && (
                            <p className="text-sm text-gray-600">
                              {item.additionalOptions
                                .map(
                                  (option) => `${option.name} ${option.price}`
                                )
                                .join(", ")}
                            </p>
                          )}
                        </div>
                        <div className="flex-shrink-0 pl-4">
                          <span className="font-semibold text-[#6B492A]">
                            {item.price}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* Flavors List */}
                <div className="mb-12">
                  <h2 className="text-[32px] font-['Domine'] text-2xl font-semibold text-center mb-8 text-[#6B492A] uppercase">
                    Flavors
                  </h2>
                  <div className="grid md:grid-cols-3 gap-4">
                    {flavorsList.map((flavor, index) => (
                      <div
                        key={index}
                        className="text-center text-gray-800 font-semibold"
                      >
                        {flavor}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </PageTransition>
  );
};

export default Menu;
