export const validateFile = (file) => {
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const ALLOWED_TYPES = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  if (!file) {
    throw new Error("Please select a resume file");
  }

  if (!ALLOWED_TYPES.includes(file.type)) {
    throw new Error("Please upload a PDF or Word document");
  }

  if (file.size > MAX_FILE_SIZE) {
    throw new Error("File size must be less than 5MB");
  }

  return true;
};
