import React, { useState } from "react";
import cateringBanner from "../assets/images/banner/catering_banner.avif"; // You'll need to add this image
import CateringForm from "../components/CateringForm";
import PageTransition from "../components/PageTransition";

const Catering = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const scrollToContent = () => {
    document
      .getElementById("catering-content")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <PageTransition>
      <div className="flex flex-col">
        {/* Banner Section */}
        <div className="relative h-[40vh] md:h-[60vh] w-full">
          <div className="absolute inset-0 bg-black/60">
            <img
              src={cateringBanner}
              alt="Catering Banner"
              className="w-full h-full object-cover object-center mix-blend-overlay"
            />
          </div>
          <div className="absolute inset-0 flex flex-col items-center">
            <div className="flex-1 flex items-center justify-center">
              <h1 className="text-[2.5rem] md:text-[4.5rem] font-[700] tracking-[4px] md:tracking-[6px] font-['Domine'] leading-[1.1] text-white uppercase">
                Catering
              </h1>
            </div>
            <div className="pb-8 md:pb-12">
              <button
                onClick={scrollToContent}
                className="animate-bounce"
                aria-label="Scroll to content"
              >
                <svg
                  className="w-12 h-12 md:w-16 md:h-16 text-white drop-shadow-lg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Content Section */}
        <div
          id="catering-content"
          className="container mx-auto px-6 md:px-4 py-12 md:py-16"
        >
          <p className="text-[16px] md:text-[23px] font-['Lato'] font-[400] leading-[1.5] text-center max-w-2xl mx-auto mb-8">
            From an office party to an at-home celebration, we can help with
            your next event. Please fill out the form below and we'll be in
            touch soon!
          </p>
          <div className="text-center">
            <button
              onClick={() => setIsFormOpen(true)}
              className="bg-[#64785d] text-white px-6 md:px-8 py-2 md:py-3 rounded
                       hover:bg-[#6B492A] transition-colors duration-300 text-base md:text-lg"
            >
              INQUIRE NOW
            </button>
          </div>
        </div>
        <CateringForm
          isOpen={isFormOpen}
          onClose={() => setIsFormOpen(false)}
        />
      </div>
    </PageTransition>
  );
};

export default Catering;
