import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import About from "./pages/About";
import Events from "./pages/Events";
import EventDetail from "./pages/EventDetail";
import Creamery from "./pages/Creamery";
import Hours from "./pages/Hours";
import Footer from "./components/Footer";
import Join from "./pages/Join";
import Contact from "./pages/Contact";
import backdrop from "./assets/images/banner/backdrop.avif";
import Catering from "./pages/Catering";
import { AnimatePresence } from "framer-motion";
import PageTransition from "./components/PageTransition";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence
      mode="wait"
      initial={false}
      onExitComplete={() => window.scrollTo(0, 0)}
    >
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/about" element={<About />} />
        <Route path="/events" element={<Events />} />
        <Route path="/creamery" element={<Creamery />} />
        <Route path="/hours" element={<Hours />} />
        <Route path="/event/:slug" element={<EventDetail />} />
        <Route path="/jobs" element={<Join />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/catering" element={<Catering />} />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    // Set false after initial animation
    const timer = setTimeout(() => setIsFirstLoad(false), 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <PageTransition key={isFirstLoad ? "initial" : "loaded"}>
      <Router>
        <div className="min-h-screen flex flex-col">
          <Navbar />
          <main className="flex-grow relative">
            {/* Backdrop container */}
            <div
              className="absolute inset-0 bg-cover bg-center bg-fixed opacity-85"
              style={{
                backgroundImage: `url(${backdrop})`,
                zIndex: -1,
              }}
            />
            {/* Content container */}
            <div className="relative z-0">
              <Suspense fallback={<div>Loading...</div>}>
                <AnimatedRoutes />
              </Suspense>
            </div>
          </main>
          <Footer />
        </div>
      </Router>
    </PageTransition>
  );
}

export default App;
