import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const CateringForm = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    company: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    eventDate: "",
    startTime: "",
    endTime: "",
    eventType: "",
    guestCount: "",
    additionalInfo: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const eventTypes = [
    "Alumni",
    "Bachelor / Bachelorette Party",
    "Birthday Party",
    "Cocktail Receiption",
    "Corporate Lunch",
    "Engagement",
    "Fundraiser",
    "Graduation",
    "Holiday Party",
    "Meeting / Presentation",
    "Photo / Film Shoot",
    "Private Dinner",
    "Rehearsal Dinner",
    "Shower",
    "Sporting Event",
    "Wedding",
    "Other",
  ];

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute of ["00", "30"]) {
        const period = hour < 12 ? "AM" : "PM";
        const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        times.push(`${displayHour}:${minute} ${period}`);
      }
    }
    return times;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Replace with your API Gateway endpoint URL
      const apiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/catering-inquiry`; // Your API endpoint

      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to submit inquiry");
      }

      // Reset form and close modal
      setFormData({
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        company: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        eventDate: "",
        startTime: "",
        endTime: "",
        eventType: "",
        guestCount: "",
        additionalInfo: "",
      });
      onClose();

      // Show success message
      alert("Thank you for your inquiry. We'll be in touch soon!");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert(
        "We're unable to process your inquiry at this time. Please try again later or contact us directly."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={onClose} className="relative z-[100]">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="relative">
                  {/* Close Button */}
                  <button
                    onClick={onClose}
                    className="absolute top-0 right-0 text-gray-500 hover:text-gray-700"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>

                  <Dialog.Title className="text-2xl font-['Domine'] text-center text-[#6B492A] mb-8 font-bold">
                    CONTACT US ABOUT YOUR CATERING INQUIRY
                  </Dialog.Title>

                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      {/* Required Fields */}
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          Email *
                        </label>
                        <input
                          type="email"
                          name="email"
                          required
                          value={formData.email}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        />
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          First Name *
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          required
                          value={formData.firstName}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        />
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          Last Name *
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          required
                          value={formData.lastName}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        />
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          Phone Number *
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          required
                          value={formData.phone}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        />
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          Company *
                        </label>
                        <input
                          type="text"
                          name="company"
                          required
                          value={formData.company}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        />
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          Address 1 *
                        </label>
                        <input
                          type="text"
                          name="address1"
                          required
                          value={formData.address1}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        />
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          Address 2 - Optional
                        </label>
                        <input
                          type="text"
                          name="address2"
                          value={formData.address2}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        />
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          City *
                        </label>
                        <input
                          type="text"
                          name="city"
                          required
                          value={formData.city}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        />
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          State *
                        </label>
                        <input
                          type="text"
                          name="state"
                          required
                          value={formData.state}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        />
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          Zip Code *
                        </label>
                        <input
                          type="text"
                          name="zipCode"
                          required
                          value={formData.zipCode}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        />
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          Event Date *
                        </label>
                        <input
                          type="date"
                          name="eventDate"
                          required
                          value={formData.eventDate}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        />
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          Start Time *
                        </label>
                        <select
                          name="startTime"
                          required
                          value={formData.startTime}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        >
                          <option value="">Select a time</option>
                          {generateTimeOptions().map((time) => (
                            <option key={time} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          End Time *
                        </label>
                        <select
                          name="endTime"
                          required
                          value={formData.endTime}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        >
                          <option value="">Select a time</option>
                          {generateTimeOptions().map((time) => (
                            <option key={time} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          Type of Event *
                        </label>
                        <select
                          name="eventType"
                          required
                          value={formData.eventType}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        >
                          <option value="">Select event type</option>
                          {eventTypes.map((type) => (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label className="block text-[#6B492A] mb-2">
                          Number of People *
                        </label>
                        <input
                          type="number"
                          name="guestCount"
                          required
                          value={formData.guestCount}
                          onChange={handleChange}
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        />
                      </div>

                      {/* Additional Information */}
                      <div className="md:col-span-2">
                        <label className="block text-[#6B492A] mb-2">
                          Additional Information - Optional
                        </label>
                        <textarea
                          name="additionalInfo"
                          value={formData.additionalInfo}
                          onChange={handleChange}
                          rows="4"
                          className="w-full p-3 border border-gray-300 rounded focus:outline-none 
                                   focus:border-[#6B492A]"
                        ></textarea>
                      </div>
                    </div>

                    {/* Submit Button */}
                    <div className="text-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`bg-[#64785d] text-white px-8 py-3 rounded 
                                 hover:bg-[#6B492A] transition-colors duration-300 
                                 ${
                                   isSubmitting
                                     ? "opacity-70 cursor-not-allowed"
                                     : ""
                                 }`}
                      >
                        {isSubmitting ? "SUBMITTING..." : "SUBMIT INQUIRY"}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CateringForm;
