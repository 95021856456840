import React from "react";
import ImageSlideshow from "../components/ImageSlideshow";
import { slideshowImages } from "../data/aboutSlideshow";
import PageTransition from "../components/PageTransition";

const About = () => {
  return (
    <PageTransition>
      <div className="flex flex-col">
        {/* Banner with Slideshow */}
        <ImageSlideshow images={slideshowImages} interval={8000} />

        {/* Content Section */}
        <div
          id="about-content"
          className="mx-auto px-4 md:px-[1.875rem] pt-12 md:pt-20 pb-12 md:pb-20 max-w-[900px] text-center"
        >
          <h1 className="text-[36px] md:text-[48px] font-['Domine'] text-[#6B492A] mb-6 font-[600] uppercase leading-[1.2] md:leading-[1.1] tracking-[.08em] md:tracking-[.10em] whitespace-pre-line">
            {"STOP BY FOR A\nWARM PLATE AND\nEVEN WARMER\nCOFFEE."}
          </h1>

          <div className="text-[18px] md:text-[21px] font-[500] font-['Lato'] leading-[1.4] md:leading-[1.5] text-black">
            <p className="mb-6 whitespace-pre-line px-4 md:px-0 sm:max-w-[480px] md:max-w-[620px] mx-auto">
              The Bistro serves fresh, made-to-order American cuisine and offers
              fair trade, organic espresso and coffee drinks. We are your
              one-stop shop for: pancakes, burgers, BBQ, salads, a hot mug of
              coffee, and more!
            </p>
            <p>We are open for breakfast, lunch, and dinner.</p>
          </div>
        </div>
      </div>
    </PageTransition>
  );
};

export default About;
