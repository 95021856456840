import React from "react";
import { Link } from "react-router-dom";
import storefront from "../assets/images/banner/storefront.avif";
import steakSalad from "../assets/images/banner/steak-salad.avif";
import iceCream from "../assets/images/banner/ice-cream.avif";
import outside from "../assets/images/banner/outside.avif";
import PageTransition from "../components/PageTransition";

const Home = () => {
  const scrollToContent = () => {
    document
      .getElementById("welcome-section")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <PageTransition>
      <div className="flex flex-col">
        {/* Top Banner Section */}
        <div className="relative h-[50vh] md:h-[60vh] w-full">
          <div className="absolute inset-0">
            <img
              src={storefront}
              alt="Bistro Espresso Storefront"
              className="w-full h-full object-cover object-center mix-blend-overlay"
            />
          </div>
          <div className="absolute inset-0 flex flex-col items-center">
            <div className="flex-1 flex items-center justify-center"></div>
            <div className="pb-8 md:pb-12">
              <button
                onClick={scrollToContent}
                className="animate-bounce"
                aria-label="Scroll to content"
              >
                <svg
                  className="w-12 h-12 md:w-16 md:h-16 text-white drop-shadow-lg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* Welcome Section */}
        <div
          id="welcome-section"
          className="py-8 md:py-16 text-center px-4 md:px-0"
        >
          <h2 className="text-[32px] md:text-[43.2px] text-[#6B492A] font-[700] tracking-[2px] md:tracking-[4px] font-['Domine'] uppercase text-center mb-4">
            WELCOME TO THE BISTRO!
          </h2>
          <p className="text-[16px] md:text-[18px] text-[#6B492A]">
            We are open 7 days a week. Stop by for breakfast, lunch, or dinner.
          </p>
        </div>

        {/* Menu Banner */}
        <div className="relative flex flex-col md:flex-row h-auto md:h-[600px]">
          <div className="w-full md:w-2/3 h-[400px] md:h-full relative">
            <img
              src={steakSalad}
              alt="Steak Salad"
              className="w-full h-full object-cover object-center"
            />
          </div>
          <div className="w-full md:w-1/3 bg-[#64785D] flex flex-col items-center justify-center text-white px-4 md:px-8 py-12 md:py-0">
            <h2 className="text-3xl md:text-4xl font-bold mb-6 md:mb-8 text-center">
              BISTRO MENU
            </h2>
            <Link
              to="/menu"
              className="bg-white text-[#64785D] px-6 md:px-8 py-3 md:py-4 rounded 
                       hover:bg-gray-100 transition-colors font-semibold text-base md:text-lg"
            >
              VIEW MENU
            </Link>
          </div>
        </div>

        {/* Creamery Banner */}
        <div className="relative h-[400px] md:h-[600px] w-full">
          <div className="absolute inset-0 bg-black/60">
            <img
              src={iceCream}
              alt="Ice Cream"
              className="w-full h-full object-cover object-center mix-blend-overlay"
            />
          </div>
          <div className="absolute inset-0 flex flex-col items-center justify-center text-white px-4 md:px-8">
            <h1 className="text-4xl md:text-5xl font-bold mb-4 md:mb-6 text-center">
              BISTRO CREAMERY
            </h1>
            <p className="text-lg md:text-xl mb-6 md:mb-8 text-center max-w-2xl px-4">
              A Taste of Sweetness! Stop by Cedar Center just across from the
              Bistro.
            </p>
            <Link
              to="/creamery"
              className="bg-white text-[#64785D] px-6 md:px-8 py-3 md:py-4 rounded 
                       hover:bg-gray-100 transition-colors font-semibold text-base md:text-lg"
            >
              SEE OUR SWEETS
            </Link>
          </div>
        </div>

        {/* Events Banner */}
        <div className="relative flex flex-col-reverse md:flex-row h-auto md:h-[600px]">
          {/* Left side - Green section with text */}
          <div className="w-full md:w-1/3 bg-[#64785D] flex flex-col items-center justify-center text-white px-4 md:px-8 py-12 md:py-0">
            <h1 className="text-3xl md:text-4xl font-bold mb-6 text-center">
              UPCOMING EVENTS
            </h1>
            <p className="text-xl mb-8 text-center">Coming back Summer 2025</p>
            <Link
              to="/events"
              className="bg-white text-[#64785D] px-6 md:px-8 py-3 md:py-4 rounded 
                       hover:bg-gray-100 transition-colors font-semibold text-base md:text-lg"
            >
              SEE EVENTS
            </Link>
          </div>

          {/* Right side - Image */}
          <div className="w-full md:w-2/3 h-[400px] md:h-full">
            <img
              src={outside}
              alt="People enjoying outside seating"
              className="w-full h-full object-cover object-center"
            />
          </div>
        </div>
      </div>
    </PageTransition>
  );
};

export default Home;
