import React from "react";
import { useParams } from "react-router-dom";
import { eventsData } from "../data/eventsData";
import PageTransition from "../components/PageTransition";

const EventDetail = () => {
  const { slug } = useParams();
  const event = eventsData[slug];

  if (!event) {
    return <useNavigate to="/events" replace />;
  }

  return (
    <PageTransition>
      <div className="container mx-auto px-4 md:px-[4rem] py-8 md:py-16">
        <div className="max-w-[900px] mx-auto">
          <h1 className="text-[32px] md:text-[43.2px] text-[#6B492A] font-[700] tracking-[2px] md:tracking-[6px] font-['Domine'] uppercase text-center mb-8 md:mb-12">
            {event.title}
          </h1>
          <div className="mb-8 md:mb-12">
            <img
              src={event.image}
              alt={event.title}
              className="w-full h-auto max-h-[600px] object-contain rounded-lg"
            />
          </div>
          <div className="text-center px-4 md:px-0">
            <h2 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">
              {event.description}
            </h2>
            <p className="text-base md:text-lg leading-relaxed text-gray-700 pb-8 md:pb-14">
              {event.subdescription}
            </p>
          </div>
        </div>
      </div>
    </PageTransition>
  );
};

export default EventDetail;
