export const menuItemsCreamery = [
  {
    name: "CUP OR CONE",
    price: "$4.75",
    additionalOptions: [
      { name: "Add a Scoop", price: "$2.50" },
      { name: "Toppings", price: "$0.50" },
    ],
  },
  {
    name: "MILKSHAKES",
    price: "$9",
  },
  {
    name: "MALTS",
    price: "$9.50",
  },
  {
    name: "HOT FUDGE SUNDAES",
    price: "$6.50",
  },
  {
    name: "BANANA SPLIT",
    price: "$8.25",
  },
  {
    name: "ROOT BEER FLOATS",
    price: "$6.75",
  },
];

export const flavorsList = [
  "Banana Split",
  "Birthday Cake",
  "Bunny Tracks",
  "Chocolate",
  "Chocolate Chip",
  "Cookies and Cream",
  "Huckleberry",
  "Mango Sorbet",
  "Mint Chocolate Chip",
  "Mocha Almond Fudge",
  "Peanut Butter",
  "Pecan Praline",
  "Peppermint",
  "Rainbow Sherbert",
  "Rocky Road",
  "Strawberry",
  "Vanilla",
];
