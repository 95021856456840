import danielKushnir from "../assets/images/events/daniel_kushnir.avif";
import pioneerJazz from "../assets/images/events/pioneer-jazz.avif";
import hiredGunn from "../assets/images/events/hired_gunn.avif";

export const eventsData = {
  "daniel-kushnir": {
    id: "daniel-kushnir",
    title: "JULY 9 | DANIEL KUSHNIR",
    image: danielKushnir,
    description: "Taco Tuesday x Daniel Kushnir",
    subdescription: `Join us for our Taco Tuesday event, featuring delicious tacos and a live performance by the talented Daniel Kushnir! Bring your friends and family for a midweek fiesta you won't want to miss!`,
  },
  "pioneer-jazz": {
    id: "pioneer-jazz",
    title: "JULY 11 | PIONEER JAZZ",
    image: pioneerJazz,
    description: "Summer Nights Live Music",
    subdescription:
      "Join us for a sensational summer evening filled with the captivating sounds of Pioneer Jazz (Joe Bellamy & Jenni Roland)!",
  },
  "hired-gunn": {
    id: "hired-gunn",
    title: "JULY 13 | HIRED GUNN",
    image: hiredGunn,
    description: "Summer Nights Live Music",
    subdescription:
      "Join us on Saturday, July 13th, at 6 PM for an amazing live band performance at The Bistro. Enjoy great tunes, delicious food, and a fantastic atmosphere. Gather your friends and family for a night to remember!",
  },
};
