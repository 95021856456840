import React, { useState } from "react";
import PageTransition from "../components/PageTransition";

const INQUIRY_TYPES = ["General Inquiry", "Press Inquiry"];
const API_ENDPOINT = `${process.env.REACT_APP_API_BASE_URL}/send-email`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    inquiryType: "General Inquiry",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [submitStatus, setSubmitStatus] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSubmitStatus("");
    setIsSubmitting(true);

    try {
      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        mode: "cors", // Ensure CORS is enabled
      });

      // Try to get the response text first in case it's not valid JSON
      const responseText = await response.text();

      let data;
      try {
        // Try to parse it as JSON if possible
        data = JSON.parse(responseText);
      } catch (parseError) {
        console.error("Failed to parse JSON response:", parseError);
        data = { message: responseText };
      }

      if (!response.ok) {
        throw new Error(data.message || "Failed to send message");
      }

      setSubmitStatus("success");
      e.target.reset();
      setFormData({
        name: "",
        email: "",
        phone: "",
        inquiryType: "General Inquiry",
        message: "",
      });
    } catch (error) {
      console.error("Error:", error);
      setError(error.message || "Failed to send message. Please try again.");
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <PageTransition>
      <div className="container mx-auto px-6 md:px-4 py-16 max-w-3xl">
        <h1
          className="text-[2.7rem] font-[700] tracking-[6px] font-['Domine'] leading-[1.1]
                       text-[#6B492A] mb-8 text-center"
        >
          CONTACT US
        </h1>

        <div className="space-y-4 mb-12 text-center">
          <p className="text-gray-700">
            Send us a message and we'll get back to you as soon as possible. You
            can also reach us by phone at (209) 890-7849.
          </p>
          <p className="text-gray-700">Looking forward to hearing from you!</p>
        </div>
        {error && (
          <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
            {error}
          </div>
        )}
        {submitStatus === "success" && (
          <div className="bg-green-50 text-green-600 p-4 rounded-lg mb-6">
            Message sent successfully! We'll get back to you soon.
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-[#6B492A] mb-2">Name *</label>
            <input
              type="text"
              name="name"
              required
              value={formData.name}
              onChange={handleInputChange}
              disabled={isSubmitting}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none
                       focus:border-[#6B492A] disabled:bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-[#6B492A] mb-2">Email *</label>
            <input
              type="email"
              name="email"
              required
              value={formData.email}
              onChange={handleInputChange}
              disabled={isSubmitting}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none
                       focus:border-[#6B492A] disabled:bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-[#6B492A] mb-2">Phone Number *</label>
            <input
              type="tel"
              name="phone"
              required
              value={formData.phone}
              onChange={handleInputChange}
              disabled={isSubmitting}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none
                       focus:border-[#6B492A] disabled:bg-gray-100"
            />
          </div>
          <div>
            <label className="block text-[#6B492A] mb-2">
              What are you getting in touch about? *
            </label>
            <select
              name="inquiryType"
              value={formData.inquiryType}
              onChange={handleInputChange}
              disabled={isSubmitting}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none
                       focus:border-[#6B492A] disabled:bg-gray-100 bg-white"
            >
              {INQUIRY_TYPES.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-[#6B492A] mb-2">Your Message *</label>
            <textarea
              name="message"
              required
              value={formData.message}
              onChange={handleInputChange}
              disabled={isSubmitting}
              rows="6"
              className="w-full p-3 border border-gray-300 rounded focus:outline-none
                       focus:border-[#6B492A] disabled:bg-gray-100"
            ></textarea>
          </div>
          <div className="flex justify-center w-full mt-6">
            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-[#64785D] text-white px-8 py-2 rounded hover:bg-[#6B492A] disabled:bg-gray-400"
            >
              {isSubmitting ? "Sending..." : "Send Message"}
            </button>
          </div>
        </form>
      </div>
    </PageTransition>
  );
};

export default Contact;
