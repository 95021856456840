import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logos/logo.avif";
import tornPaperEdge from "../assets/images/torn-paper-edge.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="sticky top-0 z-50 bg-white shadow-md">
      <div className="px-4 md:px-[1.875rem] flex justify-between items-center">
        {/* Logo Section */}
        <Link to="/" className="py-4 md:py-8">
          <img src={logo} alt="Bistro Espresso Logo" className="h-12 md:h-16" />
        </Link>

        {/* Mobile Menu Button */}
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="md:hidden p-2"
          aria-label="Toggle menu"
        >
          <svg
            className="w-6 h-6 text-[#6B492A]"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            {isMenuOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            )}
          </svg>
        </button>

        {/* Navigation Links */}
        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } absolute top-full left-0 right-0 bg-white md:relative md:block md:bg-transparent`}
        >
          <div className="flex flex-col md:flex-row md:items-center md:space-x-4 p-4 md:p-0">
            <Link
              to="/menu"
              className="text-[#6B492A] text-[14px] font-[700] font-['Lato'] px-[6.4px] py-2 md:py-[3.2px]
                       hover:opacity-70 transition-opacity uppercase"
            >
              Menu
            </Link>
            <Link
              to="/hours"
              className="text-[#6B492A] text-[14px] font-[700] font-['Lato'] px-[6.4px] py-2 md:py-[3.2px]
                       hover:opacity-70 transition-opacity uppercase"
            >
              Hours & Location
            </Link>

            <Link
              to="/catering"
              className="text-[#6B492A] text-[14px] font-[700] font-['Lato'] px-[6.4px] py-2 md:py-[3.2px]
             hover:opacity-70 transition-opacity uppercase"
            >
              Catering
            </Link>

            <Link
              to="/events"
              className="text-[#6B492A] text-[14px] font-[700] font-['Lato'] px-[6.4px] py-2 md:py-[3.2px]
             hover:opacity-70 transition-opacity uppercase"
            >
              Events
            </Link>

            <Link
              to="/creamery"
              className="text-[#6B492A] text-[14px] font-[700] font-['Lato'] px-[6.4px] py-2 md:py-[3.2px]
                       hover:opacity-70 transition-opacity uppercase"
            >
              Bistro Creamery
            </Link>
            <Link
              to="/about"
              className="text-[#6B492A] text-[14px] font-[700] font-['Lato'] px-[6.4px] py-2 md:py-[3.2px]
                       hover:opacity-70 transition-opacity uppercase"
            >
              About
            </Link>
          </div>
        </div>
      </div>

      {/* Updated torn paper effect section */}
      <div
        className="absolute left-0 right-0 w-full"
        style={{
          height: "35px",
          backgroundImage: `url(${tornPaperEdge})`,
          backgroundSize: "1500px",
          backgroundRepeat: "repeat-x",
          top: "calc(100% - 5px)",
        }}
      />
    </nav>
  );
};

export default Navbar;
