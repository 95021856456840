export const menuSections = {
  topRow: [
    "FULL MENU",
    "BREAKFAST",
    "SANDWICHES & WRAPS",
    "APPETIZER, SALAD, KIDS MENU",
  ],
  bottomRow: ["COFFEE AND BEVERAGE", "BISTRO CREAMERY"],
};

export const menuPDFs = {
  "FULL MENU": "/assets/menu/full_menu.pdf",
  BREAKFAST: "/assets/menu/breakfast_menu.pdf",
  "SANDWICHES & WRAPS": "/assets/menu/sandwiches_menu.pdf",
  "APPETIZER, SALAD, KIDS MENU": "/assets/menu/appetizer_menu.pdf",
  "COFFEE AND BEVERAGE": "/assets/menu/beverage_menu.pdf",
};

export const menuData = {
  "FULL MENU": {
    Breakfast: [
      {
        name: "Classic Breakfast",
        description: "2 eggs, bacon or sausage, toast, potatoes",
        price: "14.99",
      },
      {
        name: "Eggs Benedict",
        description: "English muffin, Canadian bacon, hollandaise",
        price: "16.99",
      },
    ],
    "Sandwiches & Wraps": [
      {
        name: "Club Sandwich",
        description: "Turkey, bacon, lettuce, tomato",
        price: "15.99",
      },
      {
        name: "Chicken Caesar Wrap",
        description: "Grilled chicken, romaine, parmesan",
        price: "14.99",
      },
    ],
    "Appetizers & Salads": [
      {
        name: "House Salad",
        description: "Mixed greens, cucumber, tomatoes",
        price: "9.99",
      },
      {
        name: "Caesar Salad",
        description: "Romaine, croutons, parmesan",
        price: "11.99",
      },
    ],
    "Kids Menu": [
      { name: "Grilled Cheese", description: "With fries", price: "8.99" },
      { name: "Chicken Tenders", description: "With fries", price: "9.99" },
    ],
  },
  BREAKFAST: {
    "Eggs & Classics": [
      {
        name: "Two Eggs Any Style",
        description: "With toast and potatoes",
        price: "12.99",
      },
      {
        name: "Breakfast Burrito",
        description: "Eggs, cheese, potatoes, choice of meat",
        price: "13.99",
      },
      {
        name: "Eggs Benedict",
        description: "English muffin, ham, hollandaise",
        price: "16.99",
      },
      {
        name: "Veggie Omelette",
        description: "Mushrooms, spinach, tomatoes, cheese",
        price: "14.99",
      },
    ],
    "From the Griddle": [
      {
        name: "Buttermilk Pancakes",
        description: "With maple syrup",
        price: "12.99",
      },
      {
        name: "French Toast",
        description: "Brioche bread, powdered sugar",
        price: "13.99",
      },
      {
        name: "Belgian Waffle",
        description: "Fresh berries, whipped cream",
        price: "14.99",
      },
    ],
  },
  "SANDWICHES & WRAPS": {
    Sandwiches: [
      {
        name: "Club Sandwich",
        description: "Turkey, bacon, lettuce, tomato",
        price: "15.99",
      },
      {
        name: "BLT",
        description: "Bacon, lettuce, tomato, mayo",
        price: "13.99",
      },
      {
        name: "Grilled Chicken",
        description: "Lettuce, tomato, avocado",
        price: "14.99",
      },
      {
        name: "Tuna Melt",
        description: "House-made tuna salad, cheddar",
        price: "13.99",
      },
    ],
    Wraps: [
      {
        name: "Chicken Caesar",
        description: "Grilled chicken, romaine, parmesan",
        price: "14.99",
      },
      {
        name: "Veggie",
        description: "Hummus, cucumber, tomato, lettuce",
        price: "12.99",
      },
      {
        name: "Turkey Avocado",
        description: "Turkey, avocado, lettuce, tomato",
        price: "14.99",
      },
    ],
  },
  "APPETIZER SALAD KIDS MENU": {
    Appetizers: [
      {
        name: "Bruschetta",
        description: "Tomatoes, basil, garlic, olive oil",
        price: "9.99",
      },
      {
        name: "Mozzarella Sticks",
        description: "With marinara sauce",
        price: "8.99",
      },
      { name: "Chicken Wings", description: "Choice of sauce", price: "12.99" },
    ],
    Salads: [
      {
        name: "House Salad",
        description: "Mixed greens, cucumber, tomatoes",
        price: "9.99",
      },
      {
        name: "Caesar Salad",
        description: "Romaine, croutons, parmesan",
        price: "11.99",
      },
      {
        name: "Greek Salad",
        description: "Feta, olives, red onion",
        price: "12.99",
      },
    ],
    "Kids Menu": [
      { name: "Grilled Cheese", description: "With fries", price: "8.99" },
      { name: "Chicken Tenders", description: "With fries", price: "9.99" },
      {
        name: "Mac & Cheese",
        description: "House-made cheese sauce",
        price: "8.99",
      },
    ],
  },
  "COFFEE AND BEVERAGE": {
    "COFFEE DRINKS": [
      {
        name: "Coffee",
        sizes: [
          { size: "Small", price: "$2.75" },
          { size: "Medium", price: "$3.25" },
          { size: "Large", price: "$3.50" }
        ]
      },
      {
        name: "Au Lait",
        sizes: [
          { size: "Small", price: "$3.50" },
          { size: "Medium", price: "$4.00" },
          { size: "Large", price: "$4.50" }
        ]
      },
      {
        name: "Espresso",
        sizes: [
          { size: "Small", price: "$2.50" },
          { size: "Medium", price: "$3.50" },
          { size: "Large", price: "$4.50" }
        ]
      },
      {
        name: "Americano",
        sizes: [
          { size: "Small", price: "$3.50" },
          { size: "Medium", price: "$4.50" },
          { size: "Large", price: "$5.50" }
        ]
      },
      {
        name: "Capuccino",
        sizes: [
          { size: "Small", price: "$4.00" },
          { size: "Medium", price: "$5.00" },
          { size: "Large", price: "$6.00" }
        ]
      },
      {
        name: "Latte",
        sizes: [
          { size: "Small", price: "$4.25" },
          { size: "Medium", price: "$5.25" },
          { size: "Large", price: "$6.25" }
        ]
      },
      {
        name: "Mocha",
        sizes: [
          { size: "Small", price: "$4.50" },
          { size: "Medium", price: "$5.50" },
          { size: "Large", price: "$6.50" }
        ]
      }
    ],
    "BLENDED DRINKS": [
      {
        name: "Frapuccino",
        sizes: [
          { size: "Small", price: "$4.75" },
          { size: "Medium", price: "$5.75" },
          { size: "Large", price: "$6.75" }
        ]
      },
      {
        name: "Smoothies",
        description: "Strawberry, Banana, Mango, Blueberry, Pineapple",
        sizes: [
          { size: "Small", price: "$4.75" },
          { size: "Medium", price: "$5.75" },
          { size: "Large", price: "$6.75" }
        ]
      }
    ],
    "ADD ONS": [
      {
        name: "Add Espresso Shot",
        price: "$1.00"
      },
      {
        name: "Non-Dairy Milk Substitute",
        price: "$0.75"
      },
      {
        name: "Add Syrup",
        price: "$0.50"
      }
    ],
    "OTHER DRINKS": [
      {
        name: "Hot Chocolate",
        sizes: [
          { size: "Small", price: "$4.00" },
          { size: "Medium", price: "$4.25" },
          { size: "Large", price: "$4.50" }
        ]
      },
      {
        name: "Hot or Iced Tea",
        sizes: [
          { size: "Small", price: "$2.75" },
          { size: "Medium", price: "$3.00" },
          { size: "Large", price: "$3.25" }
        ]
      },
      {
        name: "Ginger Lemonade",
        sizes: [
          { size: "Small", price: "$3.00" },
          { size: "Medium", price: "$3.75" },
          { size: "Large", price: "$4.50" }
        ]
      },
      {
        name: "Steamer",
        sizes: [
          { size: "Small", price: "$3.50" },
          { size: "Medium", price: "$4.00" },
          { size: "Large", price: "$4.50" }
        ]
      },
      {
        name: "Chai Latte",
        sizes: [
          { size: "Small", price: "$4.50" },
          { size: "Medium", price: "$5.00" },
          { size: "Large", price: "$5.50" }
        ]
      }
    ],
    "BEER AND WINE": [
      {
        name: "Beer",
        sizes: [
          { size: "Draft", price: "$8.50" },
          { size: "Bottled", price: "$5.50" }
        ]
      },
      {
        name: "Mimosa",
        price: "$9.00"
      },
      {
        name: "Wine Menu",
        description: "Ask about our wine menu!"
      }
    ]
  },
  "BISTRO CREAMERY": {
    "Ice Cream": [
      { name: "Single Scoop", description: "Choice of flavor", price: "3.99" },
      { name: "Double Scoop", description: "Choice of flavors", price: "5.99" },
      { name: "Waffle Cone", description: "Add to any scoop", price: "1.50" },
    ],
    Sundaes: [
      {
        name: "Classic Sundae",
        description: "Vanilla ice cream, chocolate sauce, whipped cream",
        price: "7.99",
      },
      {
        name: "Banana Split",
        description: "Three scoops, banana, toppings",
        price: "9.99",
      },
      {
        name: "Brownie Sundae",
        description: "Warm brownie, vanilla ice cream, sauce",
        price: "8.99",
      },
    ],
  },
};
